body{
     background-color: black;
}
::-webkit-scrollbar {
    width: 10px;
    height: 10px;


  }
  
  ::-webkit-scrollbar-track {
    box-shadow: black;
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-corner {
    background-color: black;
}
  ::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 10px;
    padding-right: 20px;
  }
  
  ::-webkit-scrollbar-thumb{
    width: 20px;
    height: 10px;
  }

.mainDiv{
    display: flex;
    flex-direction: column;
}
.topBar{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 30px 10px 30px;
}
.topBar>p{
    font-family: 'Single Day';
font-style: normal;
font-weight: 400;
font-size: 47.3333px;
color: #72DB73;
margin: 0px;
}
.entertainmentDiv>p{
    padding-left: 30px;
    color: white;
    font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 30.4244px;
line-height: 139.69%;
margin: 0px 0px 15px 0px;
/* or 42px */

letter-spacing: 0.02em;
}
.category{
    color: #878787;
    margin:  0px 0px 0px 30px;
    font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 25px;
}
.movie-slider-Div{
    overflow: scroll;
    border: none;
    margin: 10px 30px 10px 30px;
}

.topBar>img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
}
.movie-slider-Div{
    display: flex;
    flex-direction: row;
}
.movie-poster{
    border-radius: 20px;
    width: 250px;
    height: 170px;
    margin: 10px 20px 10px 0px;
}