body{
    color: white;
}

.Container{
    display: flex;
    justify-content: space-around;
}

.leftContainer{
    display: flex;
    flex-direction: column;
    width: 90vh;
}
.leftContainer>p{
    margin: 90px;
    margin: 120px 0px 30px 50px;
    color: #72DB73;
    font-family: 'Single Day';
    font-style: normal;
    font-weight: 400;
    font-size: 71px;
}
.leftContainer>h1{
    margin-left: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 68px;
}
.category{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    margin-left: 40px;
}
.categoryInnerDiv{
    display: flex;
    justify-content: space-evenly;
    border-radius: 15px;
    margin: 10px;
    overflow-wrap: break-word;
    padding: 0px 10px 0px 10px;
    background-color: #148A08;
}
.categoryInnerDiv>p{
  cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: white;
    margin: 10px;
}
.categoryInnerDiv>p:nth-child(2){
    color: black;
}
.delete{
    margin-left: 15px;
    color: rgb(57, 57, 57);
}
.rightContainer{
    display: flex;
    flex-direction: column;
    width: 110vh;
}
.column {
    display: flex;
    width: 24%;
    height: 230px;
    flex-wrap: wrap;
    border-radius: 15px;
    margin: 10px;
  }

  
  .column.inactive {
    background-color: gray;
    cursor: not-allowed;
  }
  .row {
    display: flex;
    padding-top: 50px;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;

  }
  .textDiv{
    width: 100%;
  }
  .textDiv>p{
    text-align: start;
    font-family: 'DM Sans';
    font-style: normal;
    padding-bottom: 20px;
    font-weight: 500;
    margin: 15px 25px 0px 15px;
    font-size: 30px;

  }
  .card>img{
    display: flex;

    overflow: hidden;
    margin: 15px;
    height: 129px;
  }
  .nextBtnDiv{
    display: flex;
    width: 85%;
    justify-content: end;
  }
 .nextBtn{
    display: flex;
    border-radius: 20px;

    margin-top: 30px;
    width: 140px;
    height: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #148A08;

 }

 .pickerHover{
  box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 40px 10px #00d7c3;
}

.buttonShadow:hover {
  box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 40px 10px #00d7c3;
}

 
  @media screen and (max-width: 1700px) {
    .card>img{
        width: 160px;

      }
     
  }
  @media screen and (min-width: 1700px) {
    .card>img{
        width: 160px;

      }
     
  }
   @media screen and (max-width: 1450px) {
    .card>img{
        width: 150px;
        margin: 15px;
      }
  }

  @media screen and (max-width: 1350px) {
    .card>img{
        width: 140px;
        margin: 15px;
      }
  }
    
  @media screen and (max-width: 1250px) {
    .card>img{
        width: 130px;
        margin-right: 20px;

      }
  } 
  @media screen and (max-width: 1200px) {
    .card>img{
        width: 120px;
        margin: 15px;
      }
  }

  @media screen and (max-width: 1100px) {
    .card>img{
        width: 110px;
        margin: 15px;
      }
      .textDiv>p{
        font-size: 25px;
      }
  }
  @media screen and (max-width: 1000px) {
    .leftContainer>h1{
      font-size: 50px;
    }
    .card>img{
        width: 100px;
        margin: 15px;
      }
  }
  @media screen and (max-width: 1000px) {
    .textDiv>p{
      font-size: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    .leftContainer>h1{
      font-size: 40px;
    }
    .textDiv>p{
      font-size: 17px;
    }
    .card>img{
        width: 90px;
        margin: 15px;
      }
  } 
 @media screen and (max-width: 700px) {
  .leftContainer>h1{
    font-size: 30px;
  }
  .card>img{
      width: 80px;
      margin: 15px;
    }
  }
   
  @media screen and (max-width: 600px) {
    .Container{
      display: inline-block;
    }
    .textDiv>p{
      font-size: 154x;
    }
    .rightContainer{
      display: flex;
      flex-direction: column;
      width: 50vh;
  }
  }  